import axios from "@/api";
import { io } from "socket.io-client";

export default {
  namespaced: true,

  state() {
    return {
      loading: false,
      prescriptions: [],
      errorMessage: "",
      healthCenters: [],
      medicalDevices: [],
      linkedPatientDevices: [],
      singleMedicalDevice: null,
      socket: null,
    };
  },

  getters: {
    getPrescriptions: (state) => state.prescriptions,
    getLoadingState: (state) => state.loading,
    getErrorMessage: (state) => state.errorMessage,
    getMedicalDevices: (state) => state.medicalDevices,
    getLinkedPatientDevices: (state) => state.linkedPatientDevices,
    getSingleMedicalDevice: (state) => state.singleMedicalDevice,
  },

  mutations: {
    SET_PRESCRIPTIONS(state, prescriptions) {
      state.prescriptions = prescriptions;
    },
    SET_LOADING_STATE(state, loadingState) {
      state.loading = loadingState;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    setHealthCenters(state, payload) {
      state.healthCenters = payload;
    },
    setMedicalDevices(state, devices) {
      state.medicalDevices = devices;
    },
    setLinkedPatientDevices(state, devices) {
      state.linkedPatientDevices = devices;
    },
    setSingleMedicalDevice(state, device) {
      state.singleMedicalDevice = device;
    },
    setSocket(state, socket) {
      state.socket = socket;
    },
  },

  actions: {
    async fetchPrescriptions({ commit }) {
      try {
        commit("SET_LOADING_STATE", true);
        commit("SET_PRESCRIPTIONS", []);
        await axios
          .all([
            axios.get("prescriptions/internal"),
            axios.get("prescriptions/external"),
          ])
          .then(
            axios.spread((internalPrescriptions, externalPrescriptions) => {
              commit("SET_PRESCRIPTIONS", [
                ...internalPrescriptions.data.data,
                ...externalPrescriptions.data.data,
              ]);
              commit("SET_LOADING_STATE", false);
            })
          );
      } catch (error) {
        const {
          response: {
            data: { errorMessage },
          },
        } = error;
        commit("SET_ERROR_MESSAGE", errorMessage);
        commit("SET_LOADING_STATE", false);
        commit("SET_PRESCRIPTIONS", []);
      }
    },
    async fetchAllHealthCenters({ commit }) {
      try {
        const response = await axios.get("/data/health_centres");
        commit("setHealthCenters", response.data.posts);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async submitTestResult(_, payload) {
        await axios.post("/data/patient/glucose_level_manual", {
          Check_Time: payload.Check_Time,
          Check_Data: {
            Blood_sugar_value: `${payload.bloodSugar} ${
              payload.units || "mg/dl"
            }`,
            Before_or_after_meals: parseInt(payload.beforeOrAfterMeals, 10),
          },
        });

    },

    async editManualTest(_, { glucoseLevelId, payload }) {
        await axios.put(`/data/patient/glucose_level/${glucoseLevelId}`, {
          Check_Time: payload.Check_Time,
          Check_Data: {
            Blood_sugar_value: `${payload.bloodSugar} ${
              payload.units || "mg/dl"
            }`,
            Before_or_after_meals: parseInt(payload.beforeOrAfterMeals, 10),
          },
        });

    },

    // submit test for another patient
    async submitTestResultForPatient(_, { patientId, payload }) {
      try {
        await axios.post(`/data/patient/connected/manual_input/${patientId}`, {
          Check_Time: payload.Check_Time,
          Check_Data: {
            Blood_sugar_value: `${payload.bloodSugar} ${
              payload.units || "mg/dl"
            }`,
            Before_or_after_meals: parseInt(payload.beforeOrAfterMeals, 10),
          },
        });
      } catch (error) {
        throw error;
      }
    },

    // Fetch medical devices
    async getMedicalDevices({ commit, rootGetters }) {
      const isAdmin = rootGetters["auth/getIsAdmin"];

      if (!isAdmin) {
        try {
          const response = await axios.get("/data/patient/medical_device");
          commit("setMedicalDevices", response.data.medical_devices);
        } catch (error) {
          commit(
            "SET_ERROR_MESSAGE",
            error.message || "Failed to fetch devices"
          );
        }
      }
    },

    async getLinkedPatientDevices({ commit }, connectedPatientId) {
      try {
        const response = await axios.get(
          `/data/patient/connected/device/${connectedPatientId}`
        );
        commit("setLinkedPatientDevices", response.data.devices);
      } catch (error) {
        commit(
          "SET_ERROR_MESSAGE",
          error.message || "Failed to fetch linked patient devices"
        );
      }
    },

    //  fetch single medical device by ID
    async getSingleMedicalDevice({ commit }, medicalDeviceId) {
      try {
        const response = await axios.get(
          `/data/patient/medical_device/${medicalDeviceId}`
        );
        commit("setSingleMedicalDevice", response.data);
        return response.data;
      } catch (error) {
        commit(
          "SET_ERROR_MESSAGE",
          error.message || "Failed to fetch medical device"
        );
        throw error;
      }
    },

    // attach meal select
    async attachMealSelect({ commit }, { glucoseLevelId, beforeMeal }) {
         await axios.patch(
          `/data/patient/patch/before_meal/${glucoseLevelId}`,
          {
            before_meal: beforeMeal,
          }
        );
    },

    async editATestForLinkedPatient(_, { glucoseLevelId, patientId, payload }) {
      try {
        await axios.put(
          `/data/patient/connected/glucose_level/${glucoseLevelId}/${patientId}`,
          {
            Check_Time: payload.Check_Time,
            Check_Data: {
              Blood_sugar_value: `${payload.bloodSugar} ${
                payload.units || "mg/dl"
              }`,
              Before_or_after_meals: parseInt(payload.beforeOrAfterMeals, 10),
            },
          }
        );
      } catch (error) {
        throw error;
      }
    },

    async updateTestNotes(_, payload) {
      try {
        await axios.put(`/data/glucose_level/note/${payload.noteId}`, {
          note: payload.note,
        });
      } catch (error) {
        throw error;
      }
    },
  },
};
