<template>
  <ModalWrapper>
    <div
      class="max-w-[724px] w-full p-6 md:p-12 rounded-[48px] bg-white relative"
    >
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-ResolutionBlue text-xl font-bold">
          Edit Blood Glucose
        </h3>
        <div class="cursor-pointer" @click="closeEditManualTestModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <!-- Test Input Section -->
      <div
        class="max-w-md w-full mx-auto flex flex-col items-center gap-[30px]"
      >
        <div
          class="max-w-[414px] w-full h-[186px] border-2 border-ClearBlue flex justify-center items-center rounded-xl"
        >
          <div class="w-[134px] flex gap-3 justify-center items-center">
            <Input
              v-model="testValue"
              type="text"
              class="input-with-dashes text-2xl font-bold text-center text-ResolutionBlue w-full bg-transparent border-none outline-none"
              :placeholder="String(editingData?.glucose_level) || '__'"
            />
            <div class="relative">
              <button
                @click="toggleDropdown"
                class="flex items-center justify-center gap-1 py-1.5 px-4 rounded-full bg-ResolutionBlue text-xs text-white font-semibold"
              >
                {{ units }}
                <img
                  :src="whiteDownArrowIcon"
                  alt="unit dropdown"
                  class="ml-1 w-4 h-4"
                  :class="{ 'rotate-180': isDropdownOpen }"
                />
              </button>
              <!-- Custom dropdown menu -->
              <div
                v-if="isDropdownOpen"
                class="absolute top-full left-0 mt-1 w-full bg-white border border-BlueChalk rounded-lg shadow-lg z-50"
              >
                <button
                  v-for="option in unitOptions"
                  :key="option"
                  @click="selectUnit(option)"
                  class="block w-full text-left px-4 py-2 text-sm text-ResolutionBlue hover:bg-BlueChalk"
                  :class="{ 'bg-BlueChalk': units === option }"
                >
                  {{ option }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Before/After Meal Selection -->
        <div
          class="flex justify-between items-center space-x-2.5 py-1 px-2 rounded-full bg-BlueChalk"
        >
          <button
            @click="setActiveTab('beforeMeal')"
            :class="[
              activeTab === 'beforeMeal'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full cursor-pointer',
            ]"
          >
            Before meal
          </button>
          <button
            @click="setActiveTab('afterMeal')"
            :class="[
              activeTab === 'afterMeal'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full cursor-pointer',
            ]"
          >
            After meal
          </button>
          <button
            @click="setActiveTab('fasting')"
            :class="[
              activeTab === 'fasting'
                ? 'bg-AsignGreen text-white'
                : 'text-Gravel',
              'text-xs font-normal py-1.5 px-2.5 rounded-full cursor-pointer',
            ]"
          >
            Fasting
          </button>
        </div>

        <!-- Date and Time Selection -->
        <div class="w-full flex items-center gap-3">
          <!-- Date Picker -->
          <div class="w-full cursor-pointer relative" @click="openDatePicker">
            <input
              type="date"
              ref="datePicker"
              v-model="selectedDate"
              :max="maxDate()"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer"
            />
            <div
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span>{{ formattedDate || "Choose date" }}</span>
              <img
                :src="calendarGrayIcon"
                alt="calendar icon"
                class="ml-2 w-5 h-5"
              />
            </div>
          </div>

          <!-- Time Picker -->
          <div class="w-full cursor-pointer relative" @click="openTimePicker">
            <input
              type="time"
              ref="timePicker"
              v-model="selectedTime"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer z-10"
            />
            <div
              class="w-full py-5 px-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span>{{ formattedTime || "Choose time" }}</span>
              <img :src="clockIcon" alt="clock icon" class="ml-2 w-5 h-5 pointer-events-none" />
            </div>
          </div>
        </div>

        <!-- Submit and Cancel Section -->
        <div class="flex justify-center items-center gap-4 mt-8">
          <BaseButton
            @click="closeEditManualTestModal"
            class="px-[52px] py-3 border border-ResolutionBlue text-ResolutionBlue rounded-full text-sm font-semibold"
          >
            Cancel
          </BaseButton>
          <BaseButton
            @click="handleSubmit"
            :disabled="isPending || !isFormValid"
            :class="[
              'px-[52px] py-3 text-white rounded-full text-sm font-semibold',
              isPending || !isFormValid
                ? 'bg-[#E0E0E0] cursor-not-allowed'
                : 'bg-ResolutionBlue cursor-pointer',
            ]"
          >
            <span v-if="isPending">
              <LoadingSpinner />
            </span>
            <span v-else> Save </span>
          </BaseButton>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref, computed, inject, watch } from "vue";
import { mapActions } from "@/hooks/mapStore";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { push } from "notivue";
import { maxDate } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import ModalWrapper from "./ModalWrapper.vue";
import Input from "@/components/main/ui/Input.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import whiteDownArrowIcon from "@/assets/icons/white-down-arrow.svg";
import calendarGrayIcon from "@/assets/icons/calendar-gray-icon.svg";
import clockIcon from "@/assets/icons/clock.svg";

const {
  "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords,
  "test/editManualTest": editManualTest,
} = mapActions();

// Props received for editing
const props = defineProps({
  patientName: String,
  patientId: String,
  editingData: Object, // Data passed for editing
});

const queryClient = useQueryClient();

const testValue = ref(String(props.editingData?.glucose_level || ""));
const selectedDate = ref(""); // Raw date value
const selectedTime = ref(""); // Raw time value
const formattedDate = ref(""); // Formatted date for display
const formattedTime = ref(""); // Formatted time for display
const activeTab = ref("beforeMeal");
const units = ref("mg/dl");
const unitOptions = ["mg/dl", "mmol/l"];
const isDropdownOpen = ref(false);

// References for the hidden input elements
const datePicker = ref(null);
const timePicker = ref(null);

const closeEditManualTestModal = inject("closeEditManualTestModal");

// Function to switch between "beforeMeal" and "afterMeal"
const setActiveTab = (tab) => {
  activeTab.value = tab;
};

// Functions to populate the form with existing data
const populateFormWithEditingData = () => {
  if (props.editingData) {
    testValue.value = String(props.editingData.glucose_level || "");
    activeTab.value = props.editingData.before_meal
      ? "beforeMeal"
      : (activeTab.value = props.editingData.after_meal
          ? "afterMeal"
          : "fasting");
    units.value = props.editingData.units || "mg/dl";

    if (props.editingData.check_time) {
      const dateTime = new Date(props.editingData.check_time);
      selectedDate.value = dateTime.toISOString().split("T")[0];
      selectedTime.value = dateTime.toTimeString().slice(0, 5);
    }

    handleDateChange();
    handleTimeChange();
  }
};

watch(
  () => props.editingData,
  () => {
    populateFormWithEditingData();
  }
);

// Form validation
const isFormValid = computed(() => {
  return testValue.value && formattedDate.value && formattedTime.value;
});

// Mutation for editing manual test
const { editManualTestMutation, isPending } = useMutation({
  mutationFn: async ({ glucoseLevelId, payload }) => {
    return await editManualTest({ glucoseLevelId, payload });
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ["bloodGlucoseRecords"] });
    push.success("Test result edited successfully!");
    closeEditManualTestModal();

    // Refetch the blood glucose records after a successful edit
    fetchBloodGlucoseRecords({
      test_type: "glucose",
    });
  },
  onError: (error) => {
    const errorMessage =
      error.response?.data?.message || "Error editing the test result.";
    push.error(errorMessage);
  },
});

// Handle submit action
const handleSubmit = () => {
  if (!isFormValid.value) {
    push.error("Please fill all fields before submitting.");
    return;
  }

  // Determine the value for `beforeOrAfterMeals`
  let beforeOrAfterMeals;
  if (activeTab.value === "beforeMeal") {
    beforeOrAfterMeals = 0;
  } else if (activeTab.value === "afterMeal") {
    beforeOrAfterMeals = 1;
  } else if (activeTab.value === "fasting") {
    beforeOrAfterMeals = 0;
  }

  const payload = {
    Check_Time: `${formattedDate.value} ${formattedTime.value}`,
    bloodSugar: testValue.value,
    beforeOrAfterMeals,
    units: units.value,
  };

  // Use mutation to submit the edit request
  editManualTestMutation({
    glucoseLevelId: props.editingData.glucose_level_id,
    payload,
  });
};

// Function to toggle dropdown
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

// const openDatePicker = () => {
//   datePicker.value.click();
// };

// // Method to open hidden time picker
// const openTimePicker = () => {
//   timePicker.value.click();
// };

const openDatePicker = () => {
  if (datePicker.value) {
    datePicker.value.click();
  }
};

const openTimePicker = () => {
  if (timePicker.value) {
    timePicker.value.click();
  }
};

// Function to select a unit
const selectUnit = (newUnit) => {
  units.value = newUnit;
  isDropdownOpen.value = false;
};

// Handle date and time changes
const handleDateChange = () => {
  if (selectedDate.value) {
    const date = new Date(selectedDate.value);
    formattedDate.value = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
  }
};

const handleTimeChange = () => {
  if (selectedTime.value) {
    const time = new Date(`1970-01-01T${selectedTime.value}:00`);
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    formattedTime.value = `${hours}:${minutes}:${seconds}`;
  }
};

// Watch for changes in selectedDate and selectedTime
watch(selectedDate, handleDateChange);
watch(selectedTime, handleTimeChange);
</script>

<style scoped>
.input-with-dashes::placeholder {
  text-align: center;
  color: #011d7e;
}
</style>
