<template>
  <div class="bg-[#F8F8FB] rounded-xl px-6 py-5">
    <div class="h-full">
      <div class="space-y-6 mx-3 h-full">
        <div class="flex items-center justify-between">
          <h3 class="text-DarkJungle text-base font-bold">Documents</h3>
          <div
            class="text-center mt-auto"
            v-if="!isPending && allDocuments.length > 0"
          >
            <router-link
              to="/patient/settings/report-and-statictics"
              class="text-OlsoGrey text-base font-semibold"
              >See all</router-link
            >
          </div>
        </div>

        <div>
          <div
            v-if="isPending"
            class="w-full mx-auto h-52 flex justify-center items-center"
          >
            <div class="w-5 h-5">
              <SpinnerIcon />
            </div>
          </div>

          <div
          v-else-if="!isPending && allDocuments.length > 0"
            class="flex flex-col gap-3"
          >
            <div
              v-for="doc in allDocuments.slice(0, 3)"
               :key="doc.id"
              class="flex justify-between items-center pb-4 border-b-[0.85px] border-Platinum"
            >
              <div class="flex items-center gap-2">
                <img
                  :src="filePresenteIcon"
                  alt="file icon"
                />
                <p
                  class="w-[154px] text-ellipsis overflow-hidden whitespace-nowrap"
                >
                  {{ doc.documents.split("documents/")[1] }}
                </p>
              </div>

              <a :href="formatDocumentUrl(doc.documents)" download>
                <img
                  :src="fileDownloadIcon"
                  alt="file download icon"
                />
              </a>
            </div>
          </div>

          <div v-else class="w-full h-64 flex justify-center items-center">
            <p class="text-base font-semibold">No documents</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  isPending: {
    type: Boolean,
    default: false,
  },
});

import { computed } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";

import filePresenteIcon from "@/assets/icons/file_present.svg"
import fileDownloadIcon from "@/assets/icons/file_download.svg"

const { "user/getAllDocumentUploads": getAllDocumentUploads } = mapActions();

// Fetch documents using vue-query
const { data, isPending, isError } = useQuery({
  queryKey: ["allDocuments"],
  queryFn: getAllDocumentUploads,
  onError: (error) => {
    console.error("Error getting patient documents:", error);
  },
});

// Computed property for formatted documents
const allDocuments = computed(() => data?.value?.document_uploads || []);

const formatDocumentUrl = (doc) => {
  return doc + "?download=true";
};
</script>
