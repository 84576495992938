<template>
  <div class="relative">
    <!-- Input Field -->
    <div class="relative w-full sm:w-auto">
      <input
        type="text"
        :value="displayValue"
        readonly
        @click="toggleCalendar"
        class="border rounded-lg px-3 py-2 cursor-pointer w-full sm:w-64 focus:outline-none focus:ring-2 focus:ring-ResolutionBlue"
        :placeholder="startDate && endDate ? '' : 'Select date range'"
      />
      <!-- Clickable Overlay -->
      <div
        v-if="showCalendar"
        class="fixed inset-0 z-40"
        @click="showCalendar = false"
      ></div>
      <!-- Calendar Popup -->
      <div
        v-if="showCalendar"
        class="absolute z-50 mt-1 bg-white border rounded-lg shadow-lg p-4 w-full sm:w-[320px]"
      >
        <!-- Header -->
        <div class="flex justify-between items-center mb-4">
          <button
            @click.stop="prevMonth"
            class="p-2 hover:bg-gray-100 rounded-full"
          >
            &lt;
          </button>
          <span class="font-semibold text-sm sm:text-base">
            {{ currentMonthYear }}
          </span>
          <button
            @click.stop="nextMonth"
            class="p-2 hover:bg-gray-100 rounded-full"
          >
            &gt;
          </button>
        </div>
        <!-- Calendar Days -->
        <div class="grid grid-cols-7 gap-2">
          <!-- Weekdays -->
          <div
            v-for="day in ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']"
            :key="day"
            class="text-center text-xs sm:text-sm text-gray-600 font-medium p-2"
          >
            {{ day }}
          </div>
          <!-- Dates -->
          <div
            v-for="{ date, current, disabled } in calendarDays"
            :key="date.toISOString()"
            @click.stop="selectDate(date)"
            class="text-center text-sm sm:text-base p-2 cursor-pointer rounded-full"
            :class="{
              'text-gray-400': !current,
              'hover:bg-blue-50': !disabled,
              'bg-ResolutionBlue text-white': isSelected(date),
              'bg-blue-100': isInRange(date),
              'opacity-50 cursor-not-allowed': disabled,
            }"
          >
            {{ date.getDate() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script setup>
  import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
  
  const props = defineProps({
    modelValue: {
      type: Object,
      default: () => ({ startDate: null, endDate: null }),
    },
  });
  
  const emit = defineEmits(['update:modelValue']);
  
  const startDate = ref(props.modelValue.startDate ? new Date(props.modelValue.startDate) : null);
  const endDate = ref(props.modelValue.endDate ? new Date(props.modelValue.endDate) : null);
  const currentMonth = ref(new Date());
  const showCalendar = ref(false);
  const selecting = ref(false);
  
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      showCalendar.value = false;
    }
  };
  
  onMounted(() => {
    document.addEventListener('keydown', handleKeyDown);
  });
  
  onBeforeUnmount(() => {
    document.removeEventListener('keydown', handleKeyDown);
  });
  
  const formatDate = (date) => {
    if (!date) return '';
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };
  
  const displayValue = computed(() => {
    if (!startDate.value && !endDate.value) return '';
    if (!endDate.value) return formatDate(startDate.value);
    return `${formatDate(startDate.value)} - ${formatDate(endDate.value)}`;
  });
  
  const currentMonthYear = computed(() => {
    return currentMonth.value.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
    });
  });
  
  const calendarDays = computed(() => {
    const year = currentMonth.value.getFullYear();
    const month = currentMonth.value.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];
  
    // Add previous month's days
    const startDay = firstDay.getDay();
    for (let i = startDay - 1; i >= 0; i--) {
      const date = new Date(year, month, -i);
      days.push({
        date,
        current: false,
        disabled: false,
      });
    }
  
    // Add current month's days
    for (let i = 1; i <= lastDay.getDate(); i++) {
      const date = new Date(year, month, i);
      days.push({
        date,
        current: true,
        disabled: false,
      });
    }
  
    // Add next month's days
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      const date = new Date(year, month + 1, i);
      days.push({
        date,
        current: false,
        disabled: false,
      });
    }
  
    return days;
  });
  
  const isSelected = (date) => {
    return (
      isSameDate(date, startDate.value) || isSameDate(date, endDate.value)
    );
  };
  
  const isInRange = (date) => {
    if (!startDate.value || !endDate.value) return false;
    return date > startDate.value && date < endDate.value;
  };
  
  const isSameDate = (date1, date2) => {
    if (!date1 || !date2) return false;
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  
  const toggleCalendar = () => {
    showCalendar.value = !showCalendar.value;
  };
  
  const selectDate = (date) => {
    if (!startDate.value || (startDate.value && endDate.value) || date < startDate.value) {
      startDate.value = date;
      endDate.value = null;
      selecting.value = true;
    } else {
      endDate.value = date;
      selecting.value = false;
      showCalendar.value = false;
    }
  
    emit('update:modelValue', {
      startDate: startDate.value,
      endDate: endDate.value,
    });
  };
  
  const prevMonth = () => {
    currentMonth.value = new Date(
      currentMonth.value.getFullYear(),
      currentMonth.value.getMonth() - 1
    );
  };
  
  const nextMonth = () => {
    currentMonth.value = new Date(
      currentMonth.value.getFullYear(),
      currentMonth.value.getMonth() + 1
    );
  };
  </script>