<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 space-y-6"
    >
      <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <LinkedAccountProfileDetails
          :patientDetails="patient_details"
          :linkedAccount="linkedPatient"
        />
        <AssistLinkedAccount :patientDetails="patient_details" />
      </div>
      <div class="border rounded-[20px] pt-11 px-10 pb-7 h-[652px]">
        <div class="flex items-center justify-between gap-2 mb-6">
          <p class="font-bold">Linked Account Vitals</p>

          <div class="flex justify-between items-center gap-5">
            <div
              class="bg-BlueChalk rounded-[18px] py-1 px-1.5 flex items-center gap-[9px]"
            >
              <button
                @click="showChart = true"
                class="py-[5px] px-[9px]"
                :class="showChart && 'bg-ResolutionBlue rounded-[18px]'"
              >
                <img
                  :src="showChart ? chartIconLight : chartIcon"
                  alt="chart icon"
                />
              </button>

              <button
                @click="showChart = false"
                class="py-[5px] px-[9px]"
                :class="!showChart && ' bg-ResolutionBlue rounded-[18px]'"
              >
                <img
                  :src="!showChart ? listIcon : listIconDark"
                  alt="list icon"
                />
              </button>
            </div>

            <button class="bg-ResolutionBlue/[0.05] p-2 rounded-md">
              <img :src="fileShareIcon" alt="share icon" />
            </button>
          </div>
        </div>

        <!-- Reusable FilterHeader Component -->
        <div class="sticky top-0 z-10 bg-white mb-5">
          <FilterHeader
            title="Linked Account Vitals"
            :selectedFilter="selectedFilter"
            :selectedTestType="selectedTestType"
            :showFilterDropdown="showFilterDropdown"
            :showTestTypeDropdown="showTestTypeDropdown"
            :average_high="average_high"
            :isLinkedAccount="true"
            @toggleFilterDropdown="toggleFilterDropdown"
            @toggleTestTypeDropdown="toggleTestTypeDropdown"
            @setFilter="setFilter"
            @setTestType="setTestType"
            @exportToPDF="exportToPDF"
          />
        </div>

        <div v-if="!showChart" class="h-[90%]">
          <!-- Table with fetched records -->
          <div class="flex-1 h-full flex flex-col py-6">
            <div
              v-if="(isPending && !isPlaceholderData)"
              class="self-center flex justify-center items-center w-6 h-6"
            >
              <SpinnerIcon />
            </div>

            <!-- Show message if no data is available -->
            <NoDataMessage
              v-else-if="isSuccess && details?.length === 0"
              text="You have no test vitals"
            />
            <!-- Show error if fetch fails -->
            <ErrorMessage v-else-if="isError && !isPending" />
            <!-- Show table if data is successfully fetched or cached data is available -->
            
            <div
              v-else-if="isSuccess || details.length > 0"
              class="custom-scrollbar overflow-auto"
            >
              <Table :columns="columns" :tableData="details" />
            </div>
          </div>
        </div>

        <GlucoseLevelChart v-else />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, watchEffect, h, watch } from "vue";
import { useRoute } from "vue-router";
import { useQuery, keepPreviousData } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { dateFormatter } from "@/utils/dateFormatter";
import { timeFormatter } from "@/utils/dateFormatter";
import { testTypes } from "@/utils/mockData/testTypes";
import FilterHeader from "@/components/main/patient/testRecords/FilterHeader.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import NoDataMessage from "@/components/main/ui/NoDataMessage.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import Table from "@/components/main/ui/table/Table.vue";
import GlucoseLevelChart from "@/components/main/patient/accountLinking/GlucoseLevelChart.vue";
import chartIcon from "@/assets/icons/fi_bar-chart-2.svg";
import chartIconLight from "@/assets/icons/fi_bar-chart-2-light.svg";
import listIcon from "@/assets/icons/fi_list.svg";
import listIconDark from "@/assets/icons/fi_list-dark.svg";
import LinkedAccountProfileDetails from "@/components/main/patient/accountLinking/LinkedAccountProfileDetails.vue";
import AssistLinkedAccount from "@/components/main/patient/accountLinking/AssistLinkedAccount.vue";
import arrowDropDownIcon from "@/assets/icons/arrow_drop_down.svg";
import fileShareIcon from "@/assets/icons/fi_share.svg";

const route = useRoute();
const id = route.params.id;
const details = ref([]);
const showChart = ref(false);
const patient_details = ref({});
const linkedPatient = ref({});
const average_high = ref(80);
const selectedTestType = ref(testTypes[0]);
const showTestTypeDropdown = ref(false);
const selectedFilter = ref("All");
const showFilterDropdown = ref(false);
const columns = ref([]);

const { "accountConnection/getLinkedAccountVitals": getLinkedAccountVitals } =
  mapActions();

const openLinkedAccountManualTestModal = inject(
  "openLinkedAccountManualTestModal"
);


// Event handlers for FilterHeader
const toggleFilterDropdown = () =>
  (showFilterDropdown.value = !showFilterDropdown.value);

const toggleTestTypeDropdown = () =>
  (showTestTypeDropdown.value = !showTestTypeDropdown.value);

const setFilter = (filter) => {
  selectedFilter.value = filter;
  showFilterDropdown.value = false;
};
const setTestType = (testType) => {
  selectedTestType.value = testType;
  showTestTypeDropdown.value = false;
  updateColumns(); // Update columns based on selected test type
};

const {
  data,
  isPending,
  isPlaceholderData,
  isSuccess,
  isError,
  refetch,
} = useQuery({
  queryKey: [
    "linked-account-vitals",
    id,
    selectedTestType,
    selectedFilter,
  ],
  queryFn: () =>
    getLinkedAccountVitals({
      id,
      test_type: selectedTestType.value.type,
      time_period:
        selectedFilter.value === "All"
          ? null
          : selectedFilter.value.toLowerCase().replace(" ", "_"),
    }),
  placeholderData: keepPreviousData,
});

// Define columns based on test type
const updateColumns = () => {
  const testType = selectedTestType.value.type;
  // Define the "Date" column to come first
  const dateColumn = {
    title: "Date",
    dataIndex: "check_time",
    render: (record) => h("span", null, dateFormatter(record.check_time)),
  };
  // Define specific columns based on test type
  const testTypeColumns = {
    glucose: [
      {
        title: "Glucose Level",
        dataIndex: "glucose_level",
        render: (record) => {
          const glucoseClass =
            record.glucose_level < 70
              ? "text-DodgerBlue font-bold"
              : record.glucose_level <= 100
              ? "text-DarkMint font-bold"
              : record.glucose_level <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: glucoseClass },
            `${record.glucose_level} ${record.units}`
          );
        },
      },
      {
        title: "Meal",
        dataIndex: "before_meal",
        render: (record) =>
          h("span", null, record.before_meal ? "Before meal" : "After meal"),
      },
    ],
    heart_rate: [
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
      {
        title: "Oxygen Saturation",
        dataIndex: "oxygen_saturation",
        render: (record) =>
          h(
            "span",
            null,
            `${record.oxygen_saturation} ${record.oxygen_saturation_unit}`
          ),
      },
      {
        title: "Perfusion Index",
        dataIndex: "perfusion_index",
        render: (record) =>
          h(
            "span",
            null,
            `${record.perfusion_index} ${record.perfusion_index_unit}`
          ),
      },
    ],
    blood_pressure: [
      {
        title: "Systolic",
        dataIndex: "systolic",
        render: (record) =>
          h("span", null, `${record.systolic} ${record.units}`),
      },
      {
        title: "Diastolic",
        dataIndex: "diastolic",
        render: (record) =>
          h("span", null, `${record.diastolic} ${record.units}`),
      },
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
    ],
    weight: [
      {
        title: "Weight",
        dataIndex: "weight",
        render: (record) => h("span", null, `${record.weight} ${record.units}`),
      },
    ],
    temperature: [
      {
        title: "Temperature",
        dataIndex: "temperature",
        render: (record) => {
          const temperatureClass =
            record.temperature < 70
              ? "text-DodgerBlue font-bold"
              : record.temperature <= 100
              ? "text-DarkMint font-bold"
              : record.temperature <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: temperatureClass },
            `${record.temperature} ${record.units}`
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record) => h("span", null, `${record.status}`),
      },
      {
        title: "Description",
        dataIndex: "description",
        render: (record) => h("span", null, `${record.description}`),
      },
    ],
    ecg: [
      {
        title: "Heart Rate",
        dataIndex: "heart_rate",
        render: (record) => {
          const heartClass =
            record.heart_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.heart_rate <= 100
              ? "text-DarkMint font-bold"
              : record.heart_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: heartClass },
            `${record.heart_rate} ${record.units}`
          );
        },
      },
      {
        title: "Analysis",
        dataIndex: "analysis",
        render: (record) => h("span", null, `${record.analysis}`),
      },
    ],
  };
  // Common columns for all test types
  const commonColumns = [
    {
      title: "Time",
      dataIndex: "check_time",
      render: (record) => h("span", null, timeFormatter(record.check_time)),
    },
    {
      title: "Input",
      dataIndex: "input",
    },
    {
      title: "Date Updated",
      dataIndex: "updated_at",
      render: (record) => {
        return h("span", null, dateFormatter(record.updated_at));
      },
    },
    {
      title: "",
      render: (record) =>
        record.input === "Manual Input"
          ? h(
              "button",
              {
                class:
                  "border border-ResolutionBlue rounded-2xl text-ResolutionBlue px-4 py-1 font-bold hover:bg-ResolutionBlue hover:text-white",
                onClick: () => handleEditClick(record),
              },
              "Edit"
            )
          : null,
    },
  ];
  // Combine columns with the "Date" column as the first column
  columns.value = [
    dateColumn,
    ...(testTypeColumns[testType] || []),
    ...commonColumns,
  ];
};


// Initialize columns on load and update when test type changes
watch(selectedTestType, updateColumns, { immediate: true });

watchEffect(() => {
  updateColumns();
  if (data.value) {
    details.value = data.value.patient_results;
    linkedPatient.value = data.value;
    patient_details.value = data.value.patient_details;
  }
});


// Add the function to handle edit clicks
const handleEditClick = (item) => {
  if (!item || !patient_details.value) {
    console.error("Missing item or patient details");
    return;
  }

  const patientName = patient_details.value.full_name; // Get patient name from details
  const patientId = patient_details.value.id; // Get patient id from details
  const glucoseLevelId = item.id;

  // Prepare the data to pass to the modal
  const existingData = {
    glucose_level: item.glucose_level,
    before_meal: item.before_meal,
    check_time: item.check_time,
    input: item.input,
    units: item.units,
    created_at: item.created_at,
    glucose_level_id: glucoseLevelId,
  };

  // Open the modal with the test data
  openLinkedAccountManualTestModal(patientId, patientName, existingData);
};
</script>
