<template>
  <div
    class="mx-auto px-4 sm:px-6 lg:px-8 py-6 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:flex-row lg:space-y-0 lg:space-x-10"
  >
    <!-- Main content container -->
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full lg:w-full h-full bg-white p-4 sm:p-6 md:p-8 lg:p-10 flex flex-col"
    >
      <div class="w-full h-full flex flex-col">
        <!-- Filter header -->
        <div class="w-full border rounded-3xl p-4 sm:p-5 h-auto overflow-x-auto">
          <div class="flex flex-wrap justify-between items-center gap-4 sm:gap-6 mb-6">
            <FilterHeader
              title="Test Log"
              :selectedFilter="selectedFilter"
              :selectedTestType="selectedTestType"
              :showFilterDropdown="showFilterDropdown"
              :showTestTypeDropdown="showTestTypeDropdown"
              :average_high="average_high"
              @toggleFilterDropdown="toggleFilterDropdown"
              @toggleTestTypeDropdown="toggleTestTypeDropdown"
              @setFilter="setFilter"
              @setTestType="setTestType"
            />
            <!-- Export section -->
            <div class="flex flex-wrap items-center gap-4">
              <DateRangeCalendar
                v-model="dateRange"
                @update:modelValue="updateDateRange"
                class="w-full sm:w-auto"
              />
              <button
                @click="exportToPDF"
                :disabled="!dateRange.startDate || !dateRange.endDate"
                class="px-4 py-2 bg-ResolutionBlue text-white rounded-lg hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Export PDF
              </button>
            </div>
          </div>

          <!-- Data table -->
          <div class="overflow-x-auto h-full flex flex-col py-6">
            <div
              v-if="isPending || isPlaceholderData"
              class="self-center flex justify-center items-center w-6 h-6"
            >
              <SpinnerIcon />
            </div>
            <Table
              v-else-if="isSuccess && sortedRecords.length > 0"
              :columns="columns"
              :tableData="sortedRecords"
              class="w-full"
            />
            <ErrorMessage v-else-if="isError && !isPending" />
            <NoDataMessage
              v-else-if="isSuccess && sortedRecords?.length === 0"
              text="You have no tests vitals"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, computed, inject, watch, h, watchEffect } from "vue";
import { useQuery, keepPreviousData } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import { push } from "notivue";
import { dateFormatter, timeFormatter } from "@/utils/dateFormatter";
import FilterHeader from "@/components/main/patient/testRecords/FilterHeader.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import NoDataMessage from "@/components/main/ui/NoDataMessage.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import Table from "@/components/main/ui/table/Table.vue";
import DateRangeCalendar from "@/components/main/ui/DateRangeCalendar.vue";
import "jspdf-autotable";
import { testTypes } from "@/utils/mockData/testTypes";

const dateRange = ref({
  startDate: null,
  endDate: null,
});

const {
  "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords,
  "user/exportPDF": exportPDF,
} = mapActions();

const updateDateRange = (newRange) => {
  dateRange.value = newRange;
};

const openEditManualTestModal = inject("openEditManualTestModal");

const average_high = ref(125);

const selectedTestType = ref(testTypes[0]);
const showTestTypeDropdown = ref(false);
const selectedFilter = ref("All");
const showFilterDropdown = ref(false);
const sortField = ref(null);
const sortDirection = ref(null);
const columns = ref([]);

const toggleFilterDropdown = () => {
  showFilterDropdown.value = !showFilterDropdown.value;
};

const toggleTestTypeDropdown = () => {
  showTestTypeDropdown.value = !showTestTypeDropdown.value;
};

const setFilter = (filter) => {
  selectedFilter.value = filter;
  showFilterDropdown.value = false;
};

const setTestType = (testType) => {
  selectedTestType.value = testType;
  showTestTypeDropdown.value = false;
  updateColumns(); // Update columns whenever test type changes
};

// Fetch blood glucose records with query
const {
  data: glucoseData,
  isPending,
  isPlaceholderData,
  isSuccess,
  isError,
} = useQuery({
  queryKey: ["bloodGlucoseRecords", selectedTestType, selectedFilter],
  queryFn: () =>
    fetchBloodGlucoseRecords({
      test_type: selectedTestType.value.type,
      time_period:
        selectedFilter.value === "All"
          ? null
          : selectedFilter.value.toLowerCase().replace(" ", "_"),
    }),
  placeholderData: keepPreviousData,
});

// Dynamically set columns based on selected test type
const updateColumns = () => {
  const testType = selectedTestType.value.type;

  // Define the "Date" column to come first
  const dateColumn = {
    title: "Date",
    dataIndex: "check_time",
    render: (record) => h("span", null, dateFormatter(record.check_time)),
  };

  // Define specific columns based on test type
  const testTypeColumns = {
    glucose: [
      {
        title: "Glucose Level",
        dataIndex: "glucose_level",
        render: (record) => {
          const glucoseClass =
            record.glucose_level < 70
              ? "text-DodgerBlue font-bold"
              : record.glucose_level <= 100
              ? "text-DarkMint font-bold"
              : record.glucose_level <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: glucoseClass },
            `${record.glucose_level} ${record.units}`
          );
        },
      },
      {
        title: "Meal",
        dataIndex: "before_meal",
        render: (record) =>
          h("span", null, record.before_meal ? "Before meal" : "After meal"),
      },
    ],
    heart_rate: [
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
      {
        title: "Oxygen Saturation",
        dataIndex: "oxygen_saturation",
        render: (record) =>
          h(
            "span",
            null,
            `${record.oxygen_saturation} ${record.oxygen_saturation_unit}`
          ),
      },
      {
        title: "Perfusion Index",
        dataIndex: "perfusion_index",
        render: (record) =>
          h(
            "span",
            null,
            `${record.perfusion_index} ${record.perfusion_index_unit}`
          ),
      },
    ],
    blood_pressure: [
      {
        title: "Systolic",
        dataIndex: "systolic",
        render: (record) =>
          h("span", null, `${record.systolic} ${record.units}`),
      },
      {
        title: "Diastolic",
        dataIndex: "diastolic",
        render: (record) =>
          h("span", null, `${record.diastolic} ${record.units}`),
      },
      {
        title: "Pulse Rate",
        dataIndex: "pulse_rate",
        render: (record) => {
          const pulseClass =
            record.pulse_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.pulse_rate <= 100
              ? "text-DarkMint font-bold"
              : record.pulse_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h("span", { class: pulseClass }, `${record.pulse_rate} bpm`);
        },
      },
    ],
    weight: [
      {
        title: "Weight",
        dataIndex: "weight",
        render: (record) => h("span", null, `${record.weight} ${record.units}`),
      },
    ],
    temperature: [
      {
        title: "Temperature",
        dataIndex: "temperature",
        render: (record) => {
          const temperatureClass =
            record.temperature < 70
              ? "text-DodgerBlue font-bold"
              : record.temperature <= 100
              ? "text-DarkMint font-bold"
              : record.temperature <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: temperatureClass },
            `${record.temperature} ${record.units}`
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record) => h("span", null, `${record.status}`),
      },
      {
        title: "Description",
        dataIndex: "description",
        render: (record) => h("span", null, `${record.description}`),
      },
    ],
    ecg: [
      {
        title: "Heart Rate",
        dataIndex: "heart_rate",
        render: (record) => {
          const heartClass =
            record.heart_rate < 70
              ? "text-DodgerBlue font-bold"
              : record.heart_rate <= 100
              ? "text-DarkMint font-bold"
              : record.heart_rate <= 125
              ? "text-OrangePeel font-bold"
              : "text-ArtyClickRed font-bold";
          return h(
            "span",
            { class: heartClass },
            `${record.heart_rate} ${record.units}`
          );
        },
      },
      {
        title: "Analysis",
        dataIndex: "analysis",
        render: (record) => h("span", null, `${record.analysis}`),
      },
    ],
  };

  // Common columns for all test types
  const commonColumns = [
    {
      title: "Time",
      dataIndex: "check_time",
      render: (record) => h("span", null, timeFormatter(record.check_time)),
    },
    {
      title: "Input",
      dataIndex: "input",
    },
    {
      title: "Date Updated",
      dataIndex: "updated_at",
      render: (record) => {
        return h("span", null, dateFormatter(record.updated_at));
      },
    },
    {
      title: "",
      render: (record) =>
        record.input === "Manual Input"
          ? h(
              "button",
              {
                class:
                  "border border-ResolutionBlue rounded-2xl text-ResolutionBlue px-4 py-1 font-bold hover:bg-ResolutionBlue hover:text-white",
                onClick: () => handleEditClick(record),
              },
              "Edit"
            )
          : null,
    },
  ];

  // Combine columns with the "Date" column as the first column
  columns.value = [
    dateColumn,
    ...(testTypeColumns[testType] || []),
    ...commonColumns,
  ];
};

// Initialize columns on load and update when test type changes
watch(selectedTestType, updateColumns, { immediate: true });

// Define sortedRecords as a computed property that watches glucoseData changes
const sortedRecords = computed(() => {
  // Ensure glucoseData is available before proceeding
  if (!glucoseData.value || !Array.isArray(glucoseData.value.results)) {
    return [];
  }

  const records = glucoseData.value.results;

  // Apply sorting logic
  if (!sortField.value || !sortDirection.value) {
    return records;
  }

  return records.sort((a, b) => {
    let fieldA = a[sortField.value];
    let fieldB = b[sortField.value];

    // Handle string comparisons
    if (typeof fieldA === "string") {
      fieldA = fieldA.toLowerCase();
      fieldB = fieldB.toLowerCase();
    }

    if (sortDirection.value === "asc") {
      return fieldA > fieldB ? 1 : -1;
    } else if (sortDirection.value === "desc") {
      return fieldA < fieldB ? 1 : -1;
    }
  });
});

const exportToPDF = async () => {
  if (!dateRange.value.startDate || !dateRange.value.endDate) {
    return;
  }

  try {
    const payload = {
      patient_id: glucoseData.value.results[0].patient_id,
      date_range: {
        start_date: dateRange.value.startDate.toISOString().split("T")[0],
        end_date: dateRange.value.endDate.toISOString().split("T")[0],
      },
    };
    const response = await exportPDF(payload);
    return response;
  } catch (error) {
    console.error("Error exporting PDF:", error);
    push.error("Unexpected error occurred while exporting the PDF");
  }
};

// Open the ManualTestModal with the data of the clicked glucose record
const handleEditClick = (glucose_record) => {
  const glucoseLevelId = glucose_record.id;
  const patientId = glucose_record.patient_id;
  const editingData = {
    glucose_level: glucose_record.glucose_level,
    before_meal: glucose_record.before_meal,
    check_time: glucose_record.check_time,
    input: glucose_record.input,
    units: glucose_record.units,
    created_at: glucose_record.created_at,
    glucose_level_id: glucoseLevelId,
  };

  openEditManualTestModal("Manual Test", patientId, editingData);
};
</script>
