<template>
  <div class="lg:container lg:mx-auto w-full lg:w-[42%] lg:pl-28 lg:pr-36">
    <div class="w-full relative">
      <div class="gap-8 mb-8 md:mb-12">
        <img :src="turbomedicLogo" class="w-40 md:w-52" alt="" />
      </div>
      <div class="block lg:hidden mb-6">
        <img :src="nurseImg" class="w-full object-cover" alt="" />
      </div>
      <div class="space-y-2 md:space-y-5 mb-0.5 md:mb-1">
        <p class="font-DuplicateSans font-semibold text-lg md:text-2xl">
          Welcome Back!
        </p>
        <p class="text-sm md:text-base text-OlsoGrey font-light">
          Sign in to your account
        </p>
      </div>
      <form @submit.prevent="loginUser" class="flex flex-col w-full">
        <div class="space-y-4 md:space-y-8 mb-2 md:my-4">
          <div>
            <label for="email" class="text-sm text-BluishGrey">Email</label>
            <Input
              class="text-sm md:text-[14px] w-full border-b border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 focus:ring-ResolutionBlue font-bold"
              type="email"
              name="email"
              v-model="email"
              @keyup.enter="loginUser"
            />
          </div>
          <div>
            <div class="flex justify-between items-center pt-4">
              <label for="password" class="text-sm text-BluishGrey"
                >Password</label
              >
              <div
                class="cursor-pointer relative"
                :class="
                  !showPassword &&
                  'before:absolute before:w-full before:h-[1px] before:bg-BluishGrey before:top-1/2 before:-translate-y-1/2 before:left-0 before:rotate-45'
                "
                @click="showPassword = !showPassword"
              >
                <img :src="passwordEye" alt="eye icon" />
              </div>
            </div>
            <Input
              @keyup.enter="loginUser"
              :type="showPassword ? 'text' : 'password'"
              name="password"
              id="password"
              class="border-b py-0.5 font-sans placeholder:font-urbanist text-sm md:text-[14px] items-center w-full border-BlueChalk text-DarkJungle focus:outline-none focus:border-ResolutionBlue focus:ring-2 focus:ring-ResolutionBlue placeholder:text-OlsoGrey font-bold"
              v-model="password"
            />
            <div
              class="text-ArtyClickRed text-sm md:text-[14px] pt-2"
              v-if="signinError === false"
            >
              Email/password do not match
            </div>
          </div>
        </div>
        <div>
          <router-link
            to="/auth/reset-password"
            class="font-bold text-sm md:text-[14px] text-ResolutionBlue hover:underline"
            >Forgot Password?</router-link
          >
        </div>
        <!-- @click="loginUser" -->
        <BaseButton
          type="submit"
          :disabled="isLoginDisabled"
          class="custom-button font-semibold bg-ResolutionBlue text-sm md:text-[14px] rounded-3xl text-white mt-8 py-4 duration-500 w-full"
          :class="!isLoginDisabled && 'hover:bg-DodgerBlue'"
        >
          <span v-if="isLoading">
            <LoadingSpinner />
          </span>
          <span v-else>Sign in</span>
        </BaseButton>
      </form>
      <!-- <div class="w-full">
        <div
          class="mt-2 md:mt-4 text-[10px] md:text-xs flex items-center justify-center space-x-1"
        >
          <p class="text-MountainMist">Don't have an account?</p>
          <router-link
            to="/auth/sign-up"
            class="text-ResolutionBlue font-semibold hover:underline"
            >Sign up</router-link
          >
        </div>
      </div> -->
    </div>

    <EmailVerificationModal
      v-show="showEmailVerificationModal"
      :email="email"
    />

    <OtpAndRecorveryCodeModal
      v-if="showModal"
      :email="email"
      :password="password"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, provide } from "vue";
import { useRouter } from "vue-router";
import { push } from "notivue";
import { mapActions } from "@/hooks/mapStore";
import { ROLES } from "@/utils/constants";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import OtpAndRecorveryCodeModal from "./modals/OtpAndRecorveryCodeModal.vue";
import EmailVerificationModal from "../main/patient/verification/EmailVerificationModal.vue";
import turbomedicLogo from "@/assets/icons/turbomedic-logo__.svg";
import nurseImg from "@/assets/images/nurse-img.png";
import passwordEye from "@/assets/icons/password_eye.svg";
import Input from "@/components/main/ui/Input.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";

const router = useRouter();

// const openEmailVerificationModal = inject('openEmailVerificationModal');

const {
  "auth/login": login,
  "twoFactorAuthentication/loggedOutPatientRequestForEmailOtp":
    loggedOutPatientRequestForEmailOtp,
} = mapActions();

const email = ref("");
const password = ref("");
const isLoading = ref(false);
const showPassword = ref(false);
const signinError = ref(null);
const showModal = ref(false);

const isEmailVerificationModalOpen = ref(false);

const isLoginDisabled = computed(() => {
  return !email.value || !password.value;
});

const showEmailVerificationModal = ref(false);

const openEmailVerificationModal = () => {
  console.log("Opening Email Verification Modal");
  showEmailVerificationModal.value = true;
  console.log("showEmailVerificationModal:", showEmailVerificationModal.value);
};

const closeEmailVerificationModal = () => {
  showEmailVerificationModal.value = false;
};

provide("openEmailVerificationModal", openEmailVerificationModal);
provide("closeEmailVerificationModal", closeEmailVerificationModal);

// Login user
const loginUser = async () => {
  const loginCredentials = {
    email: email.value,
    password: password.value,
  };
  try {
    isLoading.value = true;
    const userData = await login(loginCredentials);
    if (userData && userData[0].login === true) {
      const { roles } = userData[0].user;

      //checking the user status
      if (roles[0] === ROLES.ADMIN) {
        router.push("/admin/dashboard");
      } else if (roles[0] === ROLES.PATIENT) {
        push.success("Sign in successful!");
        router.push("/patient/dashboard");
      }
    }
  } catch (error) {
    if (error.message === "Network Error") {
      push.error("Network Error");
    } else if (
      error?.message ===
      "You have made too many requests for this feature. Please try again later."
    ) {
      push.error(error.message);
    } else if (error?.response?.data?.missing_OTP) {
      await loggedOutPatientRequestForEmailOtp(email.value);
      console.error("missing_OTP");
      showModal.value = true;
    } else if (error?.response?.data?.message === "Incorrect credentials") {
      push.error(error.response.data.message);
      signinError.value = error.response.data.message;
    } else if (
      error?.response?.data?.message ===
      "Your email has not been confirmed, please confirm your email"
    ) {
      console.log(error);
      openEmailVerificationModal();
      email.value = loginCredentials.email;
      signinError.value = error?.response?.data?.message;
    }
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  localStorage.clear();
});
</script>

<style scoped>
.custom-button:disabled {
  background-color: #808ebf;
}
</style>
