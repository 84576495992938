<template>
  <div
    class="fixed top-0 left-0 h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar z-[100000]"
  >
    <div
      class="w-full max-w-[500px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex justify-end items-center mb-6">
          <div
            class="self-start cursor-pointer"
            @click="closeEmailVerificationModal"
          >
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="space-y-4">
          <h2 class="font-bold pb-[8px] text-xl w-full text-center">
            Email Not Verified Yet
          </h2>
          <p class="text-center font-normal text-xs">
            To access all features, please verify your email address. Click the
            button below to receive a new verification link.
          </p>
          <div class="flex justify-center space-x-3">
            <BaseButton
              type="button"
              class="bg-ResolutionBlue text-white rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
              @click="resendEmail"
            >
              Resend Verification Email
            </BaseButton>
          </div>
          <div class="text-center text-xs">
            Didn’t receive the email?
            <span
              class="text-ResolutionBlue cursor-pointer"
              @click="resendEmail"
            >
              Resend email
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue";
import { push } from "notivue";
import { mapActions } from "@/hooks/mapStore";
import BaseButton from "@/components/main/ui/BaseButton.vue";

const props = defineProps({
  email: {
    type: String,
    required: true,
    default: "",
  },
});
const { "auth/resendVerificationEmail": resendVerificationEmail } =
  mapActions();
const closeEmailVerificationModal = inject("closeEmailVerificationModal");
const resendEmail = async () => {
  console.log("Sending email for verification:", props.email);
  try {
    await resendVerificationEmail(props.email);
    push.success("Verification email resent successfully.");
  } catch (error) {
    if (error.message === "Network Error") {
      push.error("Network Error. Please try again later.");
    } else {
      push.error("Failed to resend verification email. Please try again.");
    }
  }
};
</script>
