<template>
  <div
    class="h-[10%] w-full flex flex-col gap-4 md:flex-row md:items-start md:justify-between"
  >
    <!-- Title -->
    <h3
      v-if="!isLinkedAccount"
      class="font-semibold text-lg md:text-xl w-full md:w-1/6"
    >
      {{ title }}
    </h3>

    <div
      class="w-full flex flex-col items-start gap-6 md:flex-row md:items-center md:justify-between"
    >
      <!-- Left Section -->
      <div class="flex flex-wrap items-center gap-4">
        <!-- Test Type Dropdown -->
        <!-- Test Type Dropdown-->
        <div class="relative rounded bg-ResolutionBlue px-3 py-2">
          <button
            @click="toggleTestTypeDropdown"
            class="flex min-w-56 w-full items-center justify-between gap-2.5"
          >
            <span class="text-xs font-bold text-white md:text-sm">
              {{ selectedTestType.name }}</span
            >
            <img :src="arrowDown" alt="arrow down" />
          </button>
          <DropdownWrapper v-if="showTestTypeDropdown">
            <div
              class="absolute left-1/2 top-full border-ResolutionBlue -translate-x-1/2 shadow-lg rounded-lg z-10 mt-1"
            >
              <ul
                class="w-32 min-w-fit text-xs text-OsloGrey rounded-lg md:text-sm"
              >
                <li
                  v-for="testType in testTypes"
                  :key="testType.type"
                  @click="setTestType(testType)"
                  class="w-full py-2 px-4 overflow-hidden transition-colors duration-300 hover:bg-ResolutionBlue hover:text-white cursor-pointer"
                  :class="
                    testType.type
                      ? 'bg-BlueChalk text-ResolutionBlue'
                      : 'bg-ResolutionBlue text-white'
                  "
                >
                  {{ testType.name }}
                </li>
              </ul>
            </div>
          </DropdownWrapper>
        </div>


        <!-- Filter Dropdown -->
        <div class="relative">
          <button
            @click="toggleFilterDropdown"
            class="flex items-center gap-2 px-4 py-2 border border-gray-300 bg-white rounded-lg hover:bg-gray-100"
          >
            <span class="text-sm">{{ selectedFilter }}</span>
            <img
              src="@/assets/icons/arrow_drop_down.svg"
              alt="arrow icon"
              class="w-4 h-4"
            />
          </button>
          <DropdownWrapper v-if="showFilterDropdown">
            <div
              class="absolute bg-white border border-gray-200 shadow-lg rounded-lg mt-2 z-10"
            >
              <ul class="min-w-[200px] text-sm">
                <li
                  v-for="filter in filters"
                  :key="filter"
                  @click="setFilter(filter)"
                  class="py-2 px-4 hover:bg-gray-200 cursor-pointer"
                >
                  {{ filter }}
                </li>
              </ul>
            </div>
          </DropdownWrapper>
        </div>
      </div>

      <!-- Right Section -->
      <div class="flex flex-wrap items-center gap-4 md:gap-6">
        <!-- Average High Display -->
        <div
          class="flex-shrink-0 bg-OrangePeel text-white text-sm font-semibold rounded-full px-4 py-2"
        >
          Avg: {{ average_high }}mg/dl
        </div>

        <!-- Glucose Levels Indicators -->
        <div class="flex items-center gap-4">
          <div class="flex items-center gap-1">
            <div class="bg-DodgerBlue w-2 h-2 rounded-full"></div>
            <span class="text-DodgerBlue text-xs">Low</span>
          </div>
          <div class="flex items-center gap-1">
            <div class="bg-DarkMint w-2 h-2 rounded-full"></div>
            <span class="text-DarkMint text-xs">Normal</span>
          </div>
          <div class="flex items-center gap-1">
            <div class="bg-OrangePeel w-2 h-2 rounded-full"></div>
            <span class="text-OrangePeel text-xs">Average High</span>
          </div>
          <div class="flex items-center gap-1">
            <div class="bg-ArtyClickRed w-2 h-2 rounded-full"></div>
            <span class="text-ArtyClickRed text-xs">High</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import DropdownWrapper from "../dropdowns/DropdownWrapper.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import arrowDown from "@/assets/icons/white-down-arrow.svg";
import { testTypes } from "@/utils/mockData/testTypes";

// Props
const props = defineProps({
  title: {
    type: String,
    default: "History",
  },
  selectedFilter: {
    type: String,
    required: true,
  },
  showFilterDropdown: {
    type: Boolean,
    required: true,
  },
  average_high: {
    type: Number,
    required: true,
  },
  selectedTestType: {
    type: Object,
    required: true,
  },
  showTestTypeDropdown: {
    type: Boolean,
    required: true,
  },
  isLinkedAccount: {
    type: Boolean,
    default: false,
  },
});

// Filter options array
const filters = [
  "All",
  "Today",
  "Yesterday",
  "Last week",
  "Last month",
  "Last year",
];

// Emits
const emit = defineEmits([
  "toggleFilterDropdown",
  "setFilter",
  "setTestType",
  "toggleTestTypeDropdown",
]);

// Methods to handle events
const toggleFilterDropdown = () => {
  emit("toggleFilterDropdown");
};

const setFilter = (filter) => {
  emit("setFilter", filter);
};

const toggleTestTypeDropdown = () => {
  emit("toggleTestTypeDropdown");
};

const setTestType = (testType) => {
  emit("setTestType", testType);
};
</script>
