<template>
  <button
    :class="computedClasses"
    :type="type"
    :disabled="disabled || isPending || isLoading"
    @click="handleClick"
  >
    <span
      class="flex items-center justify-center gap-x-2"
      v-if="!isPending && !isLoading"
    >
      <slot />
    </span>
    <span v-else>Loading...</span>
  </button>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "button",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  classes: {
    type: String,
    default: "",
  },
  isPending: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);

const computedClasses = computed(() => {
  return `inline-flex items-center justify-center transition-all duration-200 
    ${props.disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-opacity-90"} 
    ${props.classes}`;
});

function handleClick(event) {
  if (!props.disabled && !props.isPending && !props.isLoading) {
    emit("click", event);
  }
}
</script>
